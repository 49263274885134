import classnames from 'classnames/bind'
import {
  forwardRef,
  SourceHTMLAttributes,
  useCallback,
  VideoHTMLAttributes,
} from 'react'
import { useInView } from 'react-intersection-observer'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface VideoProps extends VideoHTMLAttributes<any> {
  className?: string
  rootMargin?: string
  objectFit?: 'cover' | 'contain'
  src?: string
  poster?: string
  sources?: SourceHTMLAttributes<any>[]
  loading?: 'lazy' | 'eager'
}

function VideoComponent(
  {
    className,
    loading,
    rootMargin,
    style,
    sources,
    poster,
    ...rest
  }: VideoProps,
  ref: any,
) {
  const shouldLazyLoad = loading === 'lazy'

  const [inViewRef, inView] = useInView({
    skip: !shouldLazyLoad,
    triggerOnce: true,
    rootMargin,
  })

  const setRef = useCallback(
    (node) => {
      ref && (ref.current = node)
      inViewRef(node)
    },
    [ref, inViewRef],
  )

  // https://stackoverflow.com/questions/69524396/loading-lazy-but-for-html5-videos
  const shouldHaveSrc = !shouldLazyLoad || (shouldLazyLoad && inView)

  return (
    <video
      className={cx(className, css.Video)}
      ref={setRef}
      style={{
        ...style,
        visibility: shouldHaveSrc ? 'visible' : 'hidden',
      }}
      poster={poster}
      preload={`${shouldHaveSrc ? 'auto' : 'none'}`}
      {...rest}>
      {shouldHaveSrc &&
        sources &&
        sources.length > 0 &&
        sources.map((source, index) => (
          <source key={`source${index}`} {...source} />
        ))}
    </video>
  )
}

const VideoComponentForwarded = forwardRef(VideoComponent)

VideoComponentForwarded.defaultProps = {
  loading: 'eager',
  rootMargin: '200px 0px',
}

export default VideoComponentForwarded
